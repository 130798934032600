/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
    {
        id: 'title',
        numeric: false,
        label: 'Page Title',
        align: 'left'
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date Published',
        align: 'left'
    },
    {
        id: 'editdate',
        numeric: false,
        label: 'Date Last Edited',
        align: 'left'
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action ',
        align: 'left'
    }
];

export const defaultPage = {
    _id: '',
    title: '',
    date: '',
    editdate: ''
};
