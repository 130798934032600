import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
    {
        id: 'title',
        numeric: false,
        label: 'Notification Trigger Title',
        align: 'left'
    },
    {
        id: 'date',
        numeric: false,
        label: 'Enrolled',
        align: 'left'
    },
    {
        id: 'editdate',
        numeric: false,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'editdate',
        numeric: false,
        label: 'Trigger Status',
        align: 'left'
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action ',
        align: 'left'
    }
];

export const defaultPage = {
    _id: '',
    purpose: '',
    enrolled: '',
    visibility: ''
};
