import * as Yup from 'yup';

export type Page = {
    _id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
};

export type HeadCell = {
    id: string;
    numeric: boolean;
    label: string;
    disablePadding?: string | boolean | undefined;
    align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};

Yup.setLocale({
    string: {
        matches: '${label} must contain only numbers',
        min: '${label} must be at least ${min} characters',
        max: '${label} must be at most ${max} characters'
    }
});

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is a required field'),
    content: Yup.string().trim().required('Content is a required field')
});