/* eslint no-underscore-dangle: 0 */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Spinner from "components/spinner";

import { useDispatch } from "react-redux";
import Chip from "ui-component/extended/Chip";

import { headCells } from "../constants/variables";
import { useGQL } from "hooks/notificationTemplete/useGQL";

import { NotificationTempleteType } from "../constants/types";
import { useEffect, useState } from "react";
import { ArrangementOrder } from "types";
import {
  getPurposeNotification,
  getStatus,
  getVisibility,
} from "../helper/Enum.helper";
import { BorderEditIcon } from "ui-component/icons/BorderEditIcon";

type NotificationTempleteProps = {
  data: NotificationTempleteType[];
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string
  ) => void;
};

const NotificationTempleteTable = ({
  data,
  handleEdit,
}: NotificationTempleteProps) => {
  const [order, setOrder] = useState<ArrangementOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("createdAt");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [count, setCount] = useState<number>(1);

  const { GET_NOTIFICATION_TEMPLATES_LIST } = useGQL();
  const {
    loading,
    data: NotificationData,
    refetch,
  } = GET_NOTIFICATION_TEMPLATES_LIST();

  const handleRefetch = () => {
    refetch({
      input: {
        searchText: search,
        limit: rowsPerPage,
        skip: page,
        order,
        orderBy,
      },
    });
  };
  useEffect(() => {
    handleRefetch();
  }, [rowsPerPage, page, search]);

  useEffect(() => {
    if (NotificationData) {
      setCount(NotificationData?.getAllNotification?.pagination?.total);
    }
  }, [NotificationData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
  };

  if (loading) {
    return <Spinner />;
  }

  const getChip = (status: string) => {
    if (status === "active" || status === "Active") {
      return <Chip label={status} size="medium" chipcolor="success" />;
    }
    if (status === "disabled" || status === "Disabled") {
      return <Chip label={status} size="medium" chipcolor="orange" />;
    }

    return "";
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {NotificationData &&
              NotificationData.getAllNotification.data.length === 0 ? (
              <TableRow sx={{ borderBottom: "none" }}>
                <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                  <Typography variant="h3">No Pages Found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {NotificationData &&
                  NotificationData.getAllNotification.data.map((item) => {
                    return (
                      <TableRow key={item._id}>
                        <TableCell align="left">
                          {getPurposeNotification(item.purpose)}
                        </TableCell>
                        <TableCell align="left">{item.enrollUserId?.length}</TableCell>
                        <TableCell align="left">
                          {getVisibility(item.visibility)}
                        </TableCell>
                        <TableCell align="left">
                          {getChip(getStatus(item.status))}
                        </TableCell>
                        <TableCell align="left">
                          <BorderEditIcon
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleEdit(event, item._id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default NotificationTempleteTable;
