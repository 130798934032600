interface BorderEditBtnProps {
  style?: React.CSSProperties;
  onClick?: (event) => void;
}

export const BorderEditIcon = ({ style, onClick }: BorderEditBtnProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#E3F2FD" />
      <path
        d="M22.7332 14.5606C23.4808 13.8131 24.6927 13.8131 25.4403 14.5607C26.1878 15.3082 26.1878 16.5203 25.4402 17.2678L24.7065 18.0015L21.9993 15.2944L22.7332 14.5606ZM21.2922 16.0015L15.3383 21.9548C15.1573 22.1359 15.0209 22.3566 14.9399 22.5994L14.0257 25.3421C13.9658 25.5218 14.0125 25.7198 14.1465 25.8538C14.2804 25.9877 14.4785 26.0344 14.6581 25.9746L17.4009 25.0603C17.6437 24.9794 17.8643 24.843 18.0453 24.662L23.9993 18.7086L21.2922 16.0015Z"
        fill="#1565C0"
      />
    </svg>
  );
};
