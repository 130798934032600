import { gql } from "@apollo/client";

export const GET_GLUCOSE_THRESHOLD_BY_ID = gql`
query GetThreshold {
  getThreshold {
    message
    pagination {
      total
      hasNextPage
    }
    threshold {
      _id
      userId
      adminId
      severeHyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      severeHypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
    }
  }
}
`;