import { gql } from "@apollo/client";

export const GET_ALL_PATIENT_LIST = gql`
query GetPatients($input: BasePaginationParams!) {
  getPatients(input: $input) {
    patients {
      _id
      firstName
      lastName
      medicalRecord
    }
    Pagination {
      total
      hasNextPage
    }
  }
}
`;
