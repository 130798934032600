import { gql } from '@apollo/client';

export const GET_ADMIN_LIST = gql`
  query GetAdminList($input: GetAdminListDTO!) {
    getAdminList(input: $input) {
      message
      adminList {
        _id
        name
        email
        contacts
        dob
        address
        role
        access
        createdAt
      }
      pagination {
        total
        hasNextPage
      }

    }
  }
`;

export const GET_ADMIN = gql`
  query GetAdmin($id: String!) {
    getAdmin(id: $id) {
      message
      pagination {
        total
        hasNextPage
      }
      admin {
        _id
        name
        email
        contacts
        dob
        address
        role
        access
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
    query GetUserProfile {
        getUserProfile {
        _id
        name
        email
        contacts
        dob
        address
        role
        access
    }
  }
`;
