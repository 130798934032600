// material-ui
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    let settings = useSelector((state: any) => state.settings.settings);
    let logoUrl = '';
    if (settings) {
        for (let field of settings) {
            if (field.slug === 'logo-field') {
                logoUrl = field.value;
            }
        }
    }
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        logoUrl ? (
            <img src={logoUrl} alt="logo" width="100" height="82" />
        ) : (
            <svg
        width="144"
        height="49"
        viewBox="0 0 144 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6128 34.3896L9.54271 26.9882H7.30766V34.3896H4.36035V14.5527H11.8935C16.2198 14.5527 17.9636 17.6925 17.9636 20.8287C17.9636 23.6792 16.2443 26.6743 12.9532 26.9918L18.1355 34.3931H14.6128V34.3896ZM7.30766 17.2586V24.3988H11.1743C13.8935 24.3988 15.0128 22.9313 15.0128 20.8287C15.0128 18.7543 13.7812 17.2586 11.862 17.2586H7.30766Z"
          fill="#01B6C8"
        />
        <path
          d="M36.5179 31.6838H45.6826V34.3896H33.5986V14.5527H45.4265V17.2586H36.5214V23.277H44.2826V26.011H36.5214V31.6838H36.5179Z"
          fill="#01B6C8"
        />
        <path
          d="M110.039 34.3894L108.208 29.38H99.9867L98.1551 34.3894H95.064L102.538 14.5491H105.688L113.134 34.3894H110.039ZM100.931 26.7905H107.26L104.109 18.151L100.931 26.7905Z"
          fill="#01B6C8"
        />
        <path
          d="M125.73 28.6885H128.653C128.709 30.3889 130.284 32.0294 132.747 32.0294C135.095 32.0294 136.47 30.5336 136.47 28.8896C136.47 27.1328 134.895 26.2967 133.291 25.8663L130.772 25.2313C126.562 24.1659 125.874 21.6611 125.874 20.0207C125.874 16.5352 128.937 14.2034 132.432 14.2034C135.982 14.2034 138.761 16.4506 138.761 20.0772H135.839C135.839 18.2039 134.379 16.9092 132.344 16.9092C130.484 16.9092 128.821 18.1475 128.821 19.9607C128.821 20.5957 129.021 21.8905 131.512 22.5537L134.004 23.2451C137.326 24.1377 139.417 25.8381 139.417 28.745C139.417 32.0576 136.726 34.7352 132.744 34.7352C128.537 34.7352 125.73 31.8847 125.73 28.6885Z"
          fill="#01B6C8"
        />
        <path
          d="M75.7659 16.133L75.3835 15.9072L80.3027 7.56396L80.6851 7.78622L75.7659 16.133Z"
          fill="#1D76BB"
        />
        <path
          d="M70.817 24.0004L60.0998 42.5752L60.4822 42.7982L71.1994 24.2235L70.817 24.0004Z"
          fill="#1D76BB"
        />
        <path
          d="M76.808 13.6672C75.1799 12.8205 73.3554 12.3513 71.4572 12.316C71.38 12.316 71.2993 12.3125 71.2221 12.3125C64.7415 12.3125 59.3873 17.4701 58.9838 24.1553C58.7662 27.7396 59.9627 31.1157 62.3486 33.6628C64.6714 36.1463 67.8503 37.5151 71.2923 37.5151C78.5237 37.5151 83.1517 31.6025 83.1517 23.4216H71.1169L69.8081 26.0074H79.9237V26.1979C79.9237 30.0327 75.794 34.9257 71.2572 34.9257C66.0573 34.9257 61.8293 30.4595 61.8293 24.9703C61.8293 19.421 66.0433 14.9054 71.2221 14.9054C72.6958 14.9054 74.1308 15.2617 75.4291 15.932"
          fill="#1D76BB"
        />
        <path
          d="M88.0674 24.8961C88.0709 23.6966 89.1025 22.6806 90.306 22.6947C91.513 22.7053 92.4779 23.6684 92.4989 24.8784C92.52 26.092 91.4989 27.1327 90.2849 27.1327C89.1025 27.1327 88.0639 26.0885 88.0674 24.8961Z"
          fill="#1B75BB"
        />
        <path
          d="M78.2781 7.65926C78.2817 6.45981 79.3132 5.4438 80.5167 5.45791C81.7237 5.46849 82.6886 6.43158 82.7096 7.64162C82.7307 8.85518 81.7097 9.89588 80.4957 9.89588C79.3132 9.89588 78.2746 8.85166 78.2781 7.65926Z"
          fill="#1B75BB"
        />
        <path
          d="M58.1276 7.65926C58.1311 6.45981 59.1627 5.4438 60.3662 5.45791C61.5732 5.46849 62.5381 6.43158 62.5591 7.64162C62.5802 8.85518 61.5591 9.89588 60.3451 9.89588C59.1592 9.89588 58.1241 8.85166 58.1276 7.65926Z"
          fill="#1B75BB"
        />
        <path
          d="M78.2781 42.14C78.2817 40.9405 79.3132 39.9245 80.5167 39.9386C81.7237 39.9492 82.6886 40.9123 82.7096 42.1223C82.7307 43.3359 81.7097 44.3766 80.4957 44.3766C79.3132 44.3766 78.2746 43.3288 78.2781 42.14Z"
          fill="#1B75BB"
        />
        <path
          d="M58.5662 42.14C58.5697 40.9405 59.6013 39.9245 60.8048 39.9386C62.0118 39.9492 62.9767 40.9123 62.9977 42.1223C63.0188 43.3359 61.9977 44.3766 60.7837 44.3766C59.6013 44.3766 58.5627 43.3288 58.5662 42.14Z"
          fill="#1B75BB"
        />
        <path
          d="M48.4717 24.9066C48.4752 23.7071 49.5068 22.6911 50.7102 22.7052C51.9172 22.7158 52.8821 23.6789 52.9032 24.8889C52.9242 26.1025 51.9032 27.1432 50.6892 27.1432C49.5032 27.1432 48.4682 26.099 48.4717 24.9066Z"
          fill="#1B75BB"
        />
        <path
          d="M80.5132 42.3764H60.4609L50.433 24.9138L60.4609 7.45117H80.5132L80.5763 7.56053L90.5375 24.9103L80.5132 42.3764ZM60.7136 41.9355H80.257L90.0288 24.9173L80.257 7.8992H60.7136L50.9418 24.9173L60.7136 41.9355Z"
          fill="#1D76BB"
        />
      </svg>
        )
    );
};

export default Logo;
