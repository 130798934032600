import { lazy } from "react";
// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import PageList from "views/pageManagement";
import EditPage from "views/pageManagement/forms/editPage";
import NotificationManagement from "views/notificationManagement";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/default"))
);

// menu routing
const MenuList = Loadable(lazy(() => import("views/menu/menusList")));
const AddMenu = Loadable(lazy(() => import("views/menu/forms/addMenu")));
const EditMenu = Loadable(lazy(() => import("views/menu/forms/editMenu")));

// User managemenet routing
const AdminList = Loadable(lazy(() => import("views/userManagement/admins")));
const AdminAdd = Loadable(
  lazy(() => import("views/userManagement/admins/forms/add-admin"))
);
const AdminEdit = Loadable(
  lazy(() => import("views/userManagement/admins/forms/edit-admin"))
);

const AdminProfile = Loadable(lazy(() => import("views/profile")));
const AdminAccountProfile = Loadable(
  lazy(() => import("views/profile/user-profile"))
);
const AdminChangePassword = Loadable(
  lazy(() => import("views/profile/change-password"))
);
const UserList = Loadable(lazy(() => import("views/userManagement/users")));
const UserProfile = Loadable(
  lazy(() => import("views/userManagement/users/profile"))
);
const AppUserList = Loadable(
  lazy(() => import("views/userManagement/appUsers"))
);
const AppUserProfile = Loadable(
  lazy(() => import("views/userManagement/appUsers/profile"))
);
const AddAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/AddAppUser"))
);
const EditAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/EditAppUser"))
);

/* faq */
const FAQList = Loadable(lazy(() => import("views/FAQ")));
const AddFAQV1 = Loadable(lazy(() => import("views/FAQ/forms/addForm")));
const EditFAQV1 = Loadable(lazy(() => import("views/FAQ/forms/editForm")));
const ManageFAQ = Loadable(lazy(() => import("views/FAQ/tables")));

/* email template */
const EmailTemplateList = Loadable(lazy(() => import("views/emailTemplate")));

const EditEmailTemplate = Loadable(
  lazy(() => import("views/emailTemplate/forms/editTemplate"))
);

/* glucose threshold */
const GlucoseThreshold = Loadable(
  lazy(() => import("views/glucoseThreshold/index"))
);

/* Create new template */
const ListTemplate = Loadable(lazy(() => import("views/reportTemplate/index")));
const CreateNewTemplate = Loadable(
  lazy(() => import("views/reportTemplate/forms/CreateNewTemplate"))
);

/* Edit new template */
const EditReportTemplate = Loadable(
  lazy(() => import("views/reportTemplate/forms/EditReportTemplate"))
);

// Mock component
const MockComponent = Loadable(lazy(() => import("views/mock")));

/** Notification trigger edit component */
const EditNotificationTrigger = Loadable(
  lazy(() => import("views/notificationManagement/edit"))
);

// Error routing
// const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "default",
      element: <DashboardDefault />,
    },
    {
      path: "menu/list",
      element: <MenuList />,
      allowedRoles: ["superAdmin", "admin", "editor"],
    },
    {
      path: "menu/add",
      element: <AddMenu />,
    },
    {
      path: "menu/edit/:id",
      element: <EditMenu />,
    },
    {
      path: "user/list",
      element: <UserList />,
    },
    {
      path: "user/profile/:id",
      element: <UserProfile />,
    },
    {
      path: "app-user/list",
      element: <AppUserList />,
      allowedRoles: ["superAdmin", "admin", "editor"],
    },
    {
      path: "app-user/add",
      element: <AddAppUser />,
    },
    {
      path: "app-user/edit/:id",
      element: <EditAppUser />,
    },
    {
      path: "app-user/profile/:id",
      element: <AppUserProfile />,
    },
    {
      path: "admin/list",
      element: <AdminList />,
    },
    {
      path: "admin/add",
      element: <AdminAdd />,
    },
    {
      path: "admin/edit/:id",
      element: <AdminEdit />,
    },
    {
      path: "admin/profile",
      element: <AdminProfile />,
    },
    {
      path: "admin/account-profile",
      element: <AdminAccountProfile />,
    },
    {
      path: "admin/change-password",
      element: <AdminChangePassword />,
    },
    {
      path: "faq/list",
      element: <FAQList />,
    },
    {
      path: "faq/add",
      element: <AddFAQV1 />,
    },
    {
      path: "faq/edit/:id",
      element: <EditFAQV1 />,
    },
    {
      path: "faq/manage-faq",
      element: <ManageFAQ />,
    },
    {
      path: "email-template/list",
      element: <EmailTemplateList />,
    },
    {
      path: "email-template/edit/:id",
      element: <EditEmailTemplate />,
    },

    {
      path: "mock",
      element: <MockComponent />,
    },
    {
      path: "page-management/edit/:id",
      element: <EditPage />,
    },

    {
      path: "page-management/list",
      element: <PageList />,
    },
    {
      path: "notification-management",
      element: <NotificationManagement />,
    },
    {
      path: "notification-management/edit/:id",
      element: <EditNotificationTrigger />,
    },
    {
      path: "notification-management/create",
      element: <EditNotificationTrigger />,
    },
    {
      path: "glucose-threshold/list",
      element: <GlucoseThreshold />,
    },
    {
      path: "reports/list",
      element: <ListTemplate />,
    },
    {
      path: "reports/list/create",
      element: <CreateNewTemplate />,
    },
    {
      path: "reports/list/edit/:id",
      element: <EditReportTemplate />,
    },
  ],
};

export default MainRoutes;
