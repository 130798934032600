import { gql } from "@apollo/client";

export const CREATE_NEW_NOTIFICATION_TEMPLATE = gql`
mutation CreateNotification($createNotificationInput: CreateNotificationInput!) {
    createNotification(createNotificationInput: $createNotificationInput) {
      _id
      status
      visibility
      purpose
      condition {
        when
        condition
        data_type
        operator
        patient
        value
      }
    }
  }`

export const UPDATE_NOTIFICATION_TEMPLATE_BY_ID = gql`
mutation UpdateNotification($updateNotificationInput: UpdateNotificationInput!) {
    updateNotification(updateNotificationInput: $updateNotificationInput) {
      _id
      status
      visibility
      purpose
      enrollUserId
      condition {
        when
        data_type
        patient
        condition
        value
        operator
      }
    }
  }`

export const DELETE_ENROLLED_USER_ID = gql`
mutation DeleteNotificationEnrollUser($input: IdInput!, $enrolledId: IdInput!) {
  deleteNotificationEnrollUser(input: $input, enrolledId: $enrolledId) {
    _id
    status
    visibility
    purpose
    condition {
      when
      data_type
      patient
      condition
      value
      operator
    }
    enrollUserId
  }
}`