/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { resetForm } from "store/slices/form";

import useGQL from "hooks/useGQL";
import Spinner from "components/spinner";
import MainCard from "ui-component/cards/MainCard";

import PageTable from "./table";
import { Page } from "./constants/types";

const PageList = () => {
  const navigate = useNavigate();
  const rootDispatch = useDispatch();
  const { LIST_PAGES } = useGQL();
  const { error, loading, data, refetch } = LIST_PAGES();
  const [pages, setPages] = useState<Page[]>([]);
  const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({
    limit: 10,
    skip: 0,
  });

  useEffect(() => {
    sessionStorage.clear();
    rootDispatch(resetForm());
  }, []);

  const handleRefetch = () => {
    refetch({
      input: {
        limit: pageMeta?.limit,
        skip: pageMeta?.skip,
      },
    });
  };

  const handleEdit = async (event: any | null, id: string) => {
    navigate(`/page-management/edit/${id}`);
  };

  useEffect(() => {
    if (data?.listPages) {
      setPages(data.listPages?.page);
    }
  }, [data]);

  return (
    <MainCard
      title="Page Management"
      content={false}
      sx={{ position: "relative" }}
    >
      <>
        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <PageTable
              pages={pages}
              handleEdit={handleEdit}
              handleRefetch={handleRefetch}
            />
          </>
        )}
      </>
    </MainCard>
  );
};

export default PageList;
