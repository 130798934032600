// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
// type
import { NavItemType } from 'types';
import { IconAdmin, IconWebuser } from 'constants/icons';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const userManagement: NavItemType = {
    id: 'User Management',
    title: <FormattedMessage id="User Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'app-users',
            title: 'WebApp Users',
            type: 'item',
            icon: IconWebuser,
            url: '/app-user/list',
            breadcrumbs: false
        },
        {
            id: 'admins',
            title: 'Admin Users',
            type: 'item',
            icon: IconAdmin,
            url: '/admin/list',
            breadcrumbs: false,
            roles: ['admin', 'superAdmin']
        },
    ]
};

export default userManagement;
