import { gql } from '@apollo/client';

export const CREATE_PAGE = gql`
    mutation CreatePage($input: CreatePageDTO!) {
        createPage(input: $input) {
        message
        page {
            _id
            title
            content
            createdAt
        }
        pagination {
            total
            hasNextPage
        }
        }
    }
`;

export const UPDATE_PAGE = gql`
    mutation UpdatePage($id: String!, $input: UpdatePageDTO!) {
        updatePage(id: $id, input: $input) {
        message
        pagination {
            total
            hasNextPage
        }
        page {
            _id
            title
            status
            content
            createdAt
        }
        }
    }
`;

export const DELETE_PAGE = gql`
    mutation ($id: String!) {
        deletePage(id: $id) {
            message
        }
    }
`;

export const UPLOAD_PAGE_IMAGE = gql`
    mutation ($input: uploadImageDTO!) {
        uploadPageImage(input: $input) {
            url
        }
    }
`;
