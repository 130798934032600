/* eslint no-underscore-dangle: 0 */
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Spinner from 'components/spinner';

import { headCells } from '../constants/variables';
import useGQL from 'hooks/useGQL';

import { Page } from '../constants/types';
import { IconEdit } from 'constants/icons';

type PageTableProps = {
    pages: Page[];
    handleEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, id: string) => void;
    handleRefetch: () => void;
};

const PageTable = ({ handleEdit }: PageTableProps) => {
    const { LIST_PAGES } = useGQL();
    const { loading: pageManagementLoading, data: pageManagementData } = LIST_PAGES();

    if (pageManagementLoading) {
        return <Spinner />;
    }

    return (
        <>
            <TableContainer style={{ marginLeft: '20px'}}>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                return (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    {/* table body */}
                    <TableBody>
                        {pageManagementData.listPages.pages.length === 0 ? (
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h3">No Page Management Found</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {pageManagementData.listPages.pages.map((page) => {
                                    return (
                                        <TableRow key={page._id}>
                                            <TableCell align="left">{page.title}</TableCell>
                                            <TableCell align="left">{new Date(page.createdAt).toLocaleDateString('en-GB')}</TableCell>
                                            <TableCell align="left">{new Date(page.updatedAt).toLocaleDateString('en-GB')}</TableCell>
                                            <TableCell align="left">
                                                <IconButton id='pageEdit' onClick={(event) => handleEdit(event, page._id)}>
                                                    <IconEdit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default PageTable;
