import { Title } from './index.styles';

// assets
import { IconDashboard } from '@tabler/icons';
import EmailIcon from '@mui/icons-material/Email';
import LayersIcon from '@mui/icons-material/Layers';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ReviewsIcon from '@mui/icons-material/Reviews';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionIcon from '@mui/icons-material/SubscriptionsOutlined';
import { FormattedMessage } from 'react-intl';

// ==============================|| MENU ITEMS ||============================== //

import dashboard from './dashboard';
import userManagement from './userManagement';
// import forms from './forms';
// import elements from './elements';
// import samplePage from './sample-page';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';
import { NavItemType } from 'types';
import contentManagement from './contentManagement';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, userManagement,settings]
};

export default menuItems;
