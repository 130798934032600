import { gql } from "@apollo/client";

export const GET_ALL_TEMPLATE_LISTS = gql`
  query GetAllReportTemplates($input: GetReportTemplateDTO!) {
    getAllReportTemplates(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      reports {
        _id
        title
        dataTypes
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TEMPLATE_DETAILS = gql`
  query GetReport($id: String!) {
    getReport(id: $id) {
      message
      report {
        _id
        title
        dataTypes
        createdAt
        updatedAt
      }
    }
  }
`;

export const DATATYPES_LIST = gql`
  query GetAllDataTypes {
    getAllDataTypes {
      message
      dataTypes {
        title
      }
    }
  }
`;
