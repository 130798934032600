// third-party
import { FormattedMessage } from 'react-intl';

// assets
import EmailIcon from '@mui/icons-material/Email';
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionIcon from '@mui/icons-material/SubscriptionsOutlined';
// type
import { NavItemType } from 'types';
import {
  IconEmail,
  IconNotification,
  IconPage,
  IconReports,
  IconThreshold,
} from 'constants/icons';

const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics: IconDeviceAnalytics,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const settings: NavItemType = {
  id: 'settings',
  // title: <FormattedMessage id="Settings" />,
  icon: icons.IconDashboard,
  type: 'group',
  children: [
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      icon: IconReports,
      url: '/reports/list',
      breadcrumbs: false,
    },
    {
      id: 'glucose-threshold',
      title: 'Glucose-Threshold',
      type: 'item',
      icon: IconThreshold,
      url: '/glucose-threshold/list',
      breadcrumbs: false,
    },
    {
      id: 'page-management',
      title: 'Page Management',
      type: 'item',
      icon: IconPage,
      url: '/page-management/list',
      breadcrumbs: false,
    },
    {
      id: 'email-template',
      title: 'Email Template Management',
      type: 'item',
      icon: IconEmail,
      url: '/email-template/list',
      breadcrumbs: false,
    },
    {
      id: 'notification-management',
      title: 'Notification Management',
      type: 'item',
      icon: IconNotification,
      url: 'notification-management',
    },
  ],
};

export default settings;
