import { useMutation, useQuery } from '@apollo/client';
import { CREATE_NEW_NOTIFICATION_TEMPLATE, DELETE_ENROLLED_USER_ID, GET_ALL_NOTIFICATION_TEMPLATE, GET_NOTIFICATION_TEMPLATE_BY_ID, UPDATE_NOTIFICATION_TEMPLATE_BY_ID } from 'grapqhl';
import { CreateNotificationTemplateDto, CreateNotificationTemplete, InputId, NotificationTemplete, SingleNotificaitonResponse } from 'types/notificationTemplete';

export const useGQL = () => {
    const GET_NOTIFICATION_TEMPLATES_LIST = () =>
        useQuery<NotificationTemplete>(GET_ALL_NOTIFICATION_TEMPLATE, {
            variables: { input: {} },
            notifyOnNetworkStatusChange: true
        });

    const GET_NOTIFICATION_TEMPLATE = (input: InputId) =>
        useQuery<SingleNotificaitonResponse, { input: InputId }>(GET_NOTIFICATION_TEMPLATE_BY_ID, { variables: { input }, notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only' });

    const CREATE_NOTIFICATION_TEMPLATE = () => useMutation<CreateNotificationTemplete, { createNotificationInput: CreateNotificationTemplateDto }>(CREATE_NEW_NOTIFICATION_TEMPLATE);

    const UPDATE_NOTIFICATION_TEMPLATE = () => useMutation(UPDATE_NOTIFICATION_TEMPLATE_BY_ID);

    const DELETE_ENROLLED_USER = () => useMutation(DELETE_ENROLLED_USER_ID)

    return { GET_NOTIFICATION_TEMPLATE, GET_NOTIFICATION_TEMPLATES_LIST, CREATE_NOTIFICATION_TEMPLATE, UPDATE_NOTIFICATION_TEMPLATE, DELETE_ENROLLED_USER };
};
