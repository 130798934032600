import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Link,
  Typography,
  FormHelperText,
  Divider,
  FormControl,
  Stack,
  Button,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";

import Spinner from "components/spinner";
import MainCard from "ui-component/cards/MainCard";
import { EditorProviderWrapper } from "components/wrapper/editorProviderWrapper";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";

import useAlertDialog from "hooks/common/useAlertDialog";
import useGQL from "hooks/useGQL";
import useSnackbar from "hooks/common/useSnackbar";

import { PageTemplate } from "types/pageManagement";
import { validationSchema } from "../../constants/types";
import TinyMCE from "components/editors/TinyMCE";

const EditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [template, setTemplate] = useState<Partial<PageTemplate>>({});
  const { LIST_PAGE, UPDATE_PAGE } = useGQL();
  const { handleOpenSnackbar } = useSnackbar();
  const { getConfirmation } = useAlertDialog();
  const {
    loading: pgLoading,
    data: pgData,
    refetch,
  } = LIST_PAGE(id!);
  const [handleUpdatePage, { data }] = UPDATE_PAGE();
  const [editorState, setEditorState] = useState<any>("");

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (pgData && pgData?.listPage) {
      setTemplate(pgData?.listPage?.page || {});
      setEditorState(pgData?.listPage?.page?.content);
    }
  }, [pgLoading, pgData]);

  useEffect(() => {
    if (data?.updatePage) {
      handleOpenSnackbar({
        message: `${data?.updatePage?.message!}. Redirecting...`,
        alertType: "success",
      });
      setTimeout(() => {
        navigate("/page-management/list");
      }, 2000);
    }
  }, [data, navigate]);

  const handleEditorSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { _id, title, content } = values;
      const isConfirm = await getConfirmation({
        title: "Edit Page Template",
        message: "Are you sure you want to edit page template ?",
      });
      if (isConfirm) {
        try {
          setSubmitting(true);
          const response = await handleUpdatePage({
            variables: {
              id: _id!,
              input: {
                title,
                content: editorState?.rawHTML || content,
              },
            },
          });

          setSubmitting(false);
        } catch (error) {
          const errorMessage = "Failed to update page template";
          handleOpenSnackbar({ message: errorMessage, alertType: "error" });
          setSubmitting(false);
        }
      }
    } catch (err: any) {
      const errorMessage =
        err.message || "Error occurred while updating page template";
      handleOpenSnackbar({ message: errorMessage, alertType: "error" });
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate("/page-management/list");
  };

  const breadcrumb = (
    <div>
      <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
        <Link underline="none" color="black" href="/page-management/list">
          Page Management
        </Link>
        <Typography color="black">{pgData?.listPage?.page?.title}</Typography>
      </MuiBreadcrumbs>
    </div>
  );

  if (pgLoading || !pgData?.listPage) {
    return <Spinner />;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={template}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleEditorSubmit(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} lg={6}>
              <MainCard title={breadcrumb}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={10} md={8} lg={5}>
                    <FormControl variant="filled">
                      <TextField
                        value={values.title}
                        id="title"
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Title"
                        variant="filled"
                      />

                      {touched.title && errors.title && (
                        <FormHelperText error id="title-error">
                          {errors.title}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <TinyMCE
                      initialValue={editorState}
                      height={250}
                      setEditorState={setEditorState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  gap={1}
                  flexWrap={"wrap"}
                  mt={1.5}
                >
                  <Button variant="contained" type="submit">
                    Save Changes
                  </Button>
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Stack>
              </MainCard>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditorProviderWrapper(EditPage);
