import { gql } from "@apollo/client";

export const CREATE_NEW_TEMPLATE_MUTATION = gql`
  mutation CreateReport($input: CreateReportDTO!) {
    createReport(input: $input) {
      success
      message
      report {
        _id
        title
        dataTypes
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_TEMPLATE_MUTATION = gql`
  mutation UpdateReport($id: String!, $input: UpdateReportDTO!) {
    updateReport(id: $id, input: $input) {
      success
      message
      report {
        _id
        title
        dataTypes
        createdAt
        updatedAt
      }
    }
  }
`;
