import { gql } from "@apollo/client";

export const GET_ALL_NOTIFICATION_TEMPLATE = gql`
query GetAllNotification($input: BasePaginationParams!) {
  getAllNotification(input: $input) {
    message
    pagination {
      total
      hasNextPage
    }
    data {
      _id
      status
      visibility
      purpose
      enrollUserId
      condition {
        when
        data_type
        patient
        condition
        value
        operator
      }
    }
  }
}
`

export const GET_NOTIFICATION_TEMPLATE_BY_ID = gql`
query GetNotificationById($input: IdInput!) {
    getNotificationById(input: $input) {
      _id
      status
      visibility
      purpose
      enrollUserId
      condition {
        when
        data_type
        patient
        condition
        value
        operator
      }
    }
  }
`