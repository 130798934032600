import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
    mutation UpdateAdmin($input: UpdateAdminDTO!, $id: String!) {
        updateAdmin(input: $input, id: $id) {
        message
        admin {
            _id
            name
            email
            contacts
            dob
            address
            role
            access
          }
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation ($id: String!) {
        removeAdmin(id: $id) {
            message
            admin {
                _id
            }
        }
    }
`;

export const DISABLE_ADMIN = gql`
    mutation DisableAdmin($id: String!) {
        disableAdmin(id: $id) {
            message
            admin {
                _id
            }
        }
    }
`;
