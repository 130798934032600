import { SvgIconProps } from "@mui/material";

const IconFilter = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M14.25 15.5C14.6642 15.5 15 15.8358 15 16.25C15 16.6642 14.6642 17 14.25 17H9.75C9.33579 17 9 16.6642 9 16.25C9 15.8358 9.33579 15.5 9.75 15.5H14.25ZM16.25 11.25C16.6642 11.25 17 11.5858 17 12C17 12.4142 16.6642 12.75 16.25 12.75H7.75C7.33579 12.75 7 12.4142 7 12C7 11.5858 7.33579 11.25 7.75 11.25H16.25ZM18.25 7C18.6642 7 19 7.33579 19 7.75C19 8.16421 18.6642 8.5 18.25 8.5H5.75C5.33579 8.5 5 8.16421 5 7.75C5 7.33579 5.33579 7 5.75 7H18.25Z"
                fill="#757575"
            />
        </svg>
    );
};

const IconCheckbox = ({ ...props }: SvgIconProps) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="24.5" height="24" rx="5.5" fill="#F5F5F5" />
            <rect x="0.5" y="0.5" width="24.5" height="24" rx="5.5" stroke="#BCB1A1" />
        </svg>
    );
};

const IconChecked = ({ ...props }: SvgIconProps) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="24.5" height="24" rx="5.5" fill="#F5F5F5" />
            <path d="M9 12.5L11.5 15L16.5 10" stroke="#CB3E19" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="0.5" y="0.5" width="24.5" height="24" rx="5.5" stroke="#CB3E19" />
        </svg>
    );
};

const IconView = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#E3F2FD" />
            <g clip-path="url(#clip0_5343_39340)">
                <path d="M20 15C16.6666 15 13.82 17.0733 12.6666 20C13.82 22.9267 16.6666 25 20 25C23.3333 25 26.18 22.9267 27.3333 20C26.18 17.0733 23.3333 15 20 15ZM20 23.3333C18.16 23.3333 16.6666 21.84 16.6666 20C16.6666 18.16 18.16 16.6667 20 16.6667C21.84 16.6667 23.3333 18.16 23.3333 20C23.3333 21.84 21.84 23.3333 20 23.3333ZM20 18C18.8933 18 18 18.8933 18 20C18 21.1067 18.8933 22 20 22C21.1066 22 22 21.1067 22 20C22 18.8933 21.1066 18 20 18Z" fill="#1565C0" />
            </g>
            <defs>
                <clipPath id="clip0_5343_39340">
                    <rect width="16" height="16" fill="white" transform="translate(12 12)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconEdit = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#E3F2FD" />
            <path d="M22.7332 14.5606C23.4808 13.8131 24.6927 13.8131 25.4403 14.5607C26.1878 15.3082 26.1878 16.5203 25.4402 17.2678L24.7065 18.0015L21.9993 15.2944L22.7332 14.5606ZM21.2922 16.0015L15.3383 21.9548C15.1573 22.1359 15.0209 22.3566 14.9399 22.5994L14.0257 25.3421C13.9658 25.5218 14.0125 25.7198 14.1465 25.8538C14.2804 25.9877 14.4785 26.0344 14.6581 25.9746L17.4009 25.0603C17.6437 24.9794 17.8643 24.843 18.0453 24.662L23.9993 18.7086L21.2922 16.0015Z" fill="#1565C0" />
        </svg>
    );
};



const IconDisable = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#E3F2FD" />
            <g clip-path="url(#clip0_5343_39341)">
                <path d="M20 13.3335C16.32 13.3335 13.3334 16.3202 13.3334 20.0002C13.3334 23.6802 16.32 26.6668 20 26.6668C23.68 26.6668 26.6667 23.6802 26.6667 20.0002C26.6667 16.3202 23.68 13.3335 20 13.3335ZM14.6667 20.0002C14.6667 17.0535 17.0534 14.6668 20 14.6668C21.2334 14.6668 22.3667 15.0868 23.2667 15.7935L15.7934 23.2668C15.0867 22.3668 14.6667 21.2335 14.6667 20.0002ZM20 25.3335C18.7667 25.3335 17.6334 24.9135 16.7334 24.2068L24.2067 16.7335C24.9134 17.6335 25.3334 18.7668 25.3334 20.0002C25.3334 22.9468 22.9467 25.3335 20 25.3335Z" fill="#1565C0" />
            </g>
            <defs>
                <clipPath id="clip0_5343_39341">
                    <rect width="16" height="16" fill="white" transform="translate(12 12)" />
                </clipPath>
            </defs>
        </svg>
    );
};

const IconBirthday = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M14.5 3C15.8807 3 17 4.11929 17 5.5V10.416C16.6825 10.2773 16.3478 10.1706 16 10.1V7H4V14.5C4 15.3284 4.67157 16 5.5 16H10.1C10.1706 16.3478 10.2773 16.6825 10.416 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5C3 4.11929 4.11929 3 5.5 3H14.5ZM14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V6H16V5.5C16 4.67157 15.3284 4 14.5 4ZM15.0015 12C13.3438 12 12 13.3438 12 15.0015C12 16.6592 13.3438 18.003 15.0015 18.003C15.4614 18.003 15.8916 17.898 16.2847 17.7105C16.5339 17.5916 16.8324 17.6972 16.9513 17.9465C17.0702 18.1957 16.9645 18.4941 16.7153 18.613C16.1945 18.8615 15.6177 19.003 15.0015 19.003C12.7915 19.003 11 17.2115 11 15.0015C11 12.7915 12.7915 11 15.0015 11C17.2102 11 19.0009 12.7894 19.003 14.9975L19.003 15V15.498C19.003 16.3272 18.3308 16.9995 17.5015 16.9995C17.0428 16.9995 16.6321 16.7938 16.3567 16.4695C16.0001 16.7988 15.5236 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C15.4359 13 15.8393 13.1395 16.1679 13.3762C16.2562 13.2977 16.3725 13.25 16.5 13.25C16.7761 13.25 17 13.4739 17 13.75V15.498C17 15.775 17.2245 15.9995 17.5015 15.9995C17.7785 15.9995 18.003 15.775 18.003 15.498V15.0015C18.003 13.3438 16.6592 12 15.0015 12ZM14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15Z"
                fill="#D37404"
            />
        </svg>
    );
};

const IconDelete = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7 8C7 5.79086 8.79086 4 11 4C13.2091 4 15 5.79086 15 8C15 10.2091 13.2091 12 11 12C8.79086 12 7 10.2091 7 8ZM4 15C4 13.8869 4.90315 13 6.00873 13L12.2572 13C11.4718 13.9509 11 15.1704 11 16.5C11 17.8031 11.4532 19.0003 12.2105 19.9427C11.8155 19.9811 11.4109 20 11 20C9.14526 20 7.41697 19.614 6.13499 18.7966C4.83281 17.9663 4 16.6912 4 15ZM21 16.5C21 18.9853 18.9853 21 16.5 21C14.0147 21 12 18.9853 12 16.5C12 14.0147 14.0147 12 16.5 12C18.9853 12 21 14.0147 21 16.5ZM18.3536 15.3536C18.5488 15.1583 18.5488 14.8417 18.3536 14.6464C18.1583 14.4512 17.8417 14.4512 17.6464 14.6464L16.5 15.7929L15.3536 14.6464C15.1583 14.4512 14.8417 14.4512 14.6464 14.6464C14.4512 14.8417 14.4512 15.1583 14.6464 15.3536L15.7929 16.5L14.6464 17.6464C14.4512 17.8417 14.4512 18.1583 14.6464 18.3536C14.8417 18.5488 15.1583 18.5488 15.3536 18.3536L16.5 17.2071L17.6464 18.3536C17.8417 18.5488 18.1583 18.5488 18.3536 18.3536C18.5488 18.1583 18.5488 17.8417 18.3536 17.6464L17.2071 16.5L18.3536 15.3536Z" fill="#D84315" />
        </svg>
    );
};

const DashboardIcon = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.2514 2.49805C19.9844 2.49805 21.4007 3.85437 21.4963 5.56352L21.5014 5.74794L21.5008 17.7518C21.5006 19.5466 20.0456 21.0017 18.2507 21.0017H6.25006C4.51701 21.0017 3.10076 19.6454 3.00514 17.9362L3 17.7518L3.0006 5.74796C3.00081 3.95313 4.45582 2.49805 6.25065 2.49805H18.2514ZM11.5 9.49805L4.5 9.49905L4.5001 17.7517L4.50728 17.9124C4.58839 18.8036 5.33765 19.5017 6.25 19.5017L11.5 19.501V9.49805ZM20 15.498L13 15.499V19.501L18.2506 19.5017C19.2172 19.5017 20.0007 18.7181 20.0008 17.7516L20 15.498ZM18.2514 3.99805L13 3.99705V13.999L20 13.998L20.0013 5.74802L19.9941 5.58729C19.913 4.69609 19.1638 3.99805 18.2514 3.99805ZM11.5 3.99705L6.25073 3.99805L6.10723 4.00386C5.20785 4.07693 4.50071 4.82994 4.5006 5.74808L4.5 7.99905L11.5 7.99805V3.99705Z" fill="black" />
        </svg>
    );
};

const IconAdmin = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path d="M8.31376 13.4992C8.48617 12.9657 8.72568 12.4624 9.02233 11.9992H2.25278C1.01076 11.9992 0.00390625 13.0061 0.00390625 14.2481V14.8258C0.00390625 15.7185 0.322425 16.582 0.902186 17.2609C2.46849 19.0952 4.8545 20.0004 8.00036 20.0004C8.60027 20.0004 9.1726 19.9674 9.71693 19.9014C9.32586 19.4767 8.99065 18.9997 8.72294 18.4821C8.48833 18.4943 8.24749 18.5004 8.00036 18.5004C5.26206 18.5004 3.29618 17.7546 2.04287 16.2869C1.69502 15.8795 1.50391 15.3614 1.50391 14.8258V14.2481C1.50391 13.8345 1.83919 13.4992 2.25278 13.4992H8.31376ZM8.00036 0.00390625C10.7618 0.00390625 13.0004 2.24248 13.0004 5.00391C13.0004 7.76533 10.7618 10.0039 8.00036 10.0039C5.23894 10.0039 3.00036 7.76533 3.00036 5.00391C3.00036 2.24248 5.23894 0.00390625 8.00036 0.00390625ZM8.00036 1.50391C6.06737 1.50391 4.50036 3.07091 4.50036 5.00391C4.50036 6.9369 6.06737 8.50391 8.00036 8.50391C9.93336 8.50391 11.5004 6.9369 11.5004 5.00391C11.5004 3.07091 9.93336 1.50391 8.00036 1.50391ZM20 15.5C20 18.5376 17.5376 21 14.5 21C11.4624 21 9 18.5376 9 15.5C9 12.4624 11.4624 10 14.5 10C17.5376 10 20 12.4624 20 15.5ZM15.0554 12.4206C14.8806 11.8598 14.1194 11.8598 13.9446 12.4206L13.3876 14.2077H11.5851C11.0194 14.2077 10.7842 14.9623 11.2418 15.3089L12.7001 16.4134L12.1431 18.2004C11.9683 18.7612 12.584 19.2276 13.0417 18.881L14.5 17.7766L15.9583 18.881C16.416 19.2276 17.0317 18.7612 16.8569 18.2004L16.2999 16.4134L17.7582 15.3089C18.2158 14.9623 17.9806 14.2077 17.4149 14.2077H15.6124L15.0554 12.4206Z" fill="black" />
        </svg>
    );
};

const IconWebuser = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M13.7542 11.9997C14.9962 11.9997 16.003 13.0065 16.003 14.2486V14.824C16.003 15.7183 15.6835 16.5831 15.1019 17.2625C13.5326 19.096 11.1454 20.0008 8 20.0008C4.85414 20.0008 2.46812 19.0957 0.90182 17.2614C0.322059 16.5825 0.00354004 15.719 0.00354004 14.8262V14.2486C0.00354004 13.0065 1.0104 11.9997 2.25242 11.9997H13.7542ZM13.7542 13.4997H2.25242C1.83882 13.4997 1.50354 13.835 1.50354 14.2486V14.8262C1.50354 15.3619 1.69465 15.88 2.04251 16.2874C3.29582 17.755 5.26169 18.5008 8 18.5008C10.7383 18.5008 12.7059 17.755 13.9624 16.2871C14.3113 15.8795 14.503 15.3605 14.503 14.824V14.2486C14.503 13.835 14.1678 13.4997 13.7542 13.4997ZM8 0.00439453C10.7614 0.00439453 13 2.24297 13 5.00439C13 7.76582 10.7614 10.0044 8 10.0044C5.23857 10.0044 3 7.76582 3 5.00439C3 2.24297 5.23857 0.00439453 8 0.00439453ZM8 1.50439C6.067 1.50439 4.5 3.0714 4.5 5.00439C4.5 6.93739 6.067 8.50439 8 8.50439C9.93299 8.50439 11.5 6.93739 11.5 5.00439C11.5 3.0714 9.93299 1.50439 8 1.50439Z" fill="black" />
        </svg>
    );
};

const IconReports = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
            <path d="M13.7542 11.9997C14.9962 11.9997 16.003 13.0065 16.003 14.2486V14.824C16.003 15.7183 15.6835 16.5831 15.1019 17.2625C13.5326 19.096 11.1454 20.0008 8 20.0008C4.85414 20.0008 2.46812 19.0957 0.90182 17.2614C0.322059 16.5825 0.00354004 15.719 0.00354004 14.8262V14.2486C0.00354004 13.0065 1.0104 11.9997 2.25242 11.9997H13.7542ZM13.7542 13.4997H2.25242C1.83882 13.4997 1.50354 13.835 1.50354 14.2486V14.8262C1.50354 15.3619 1.69465 15.88 2.04251 16.2874C3.29582 17.755 5.26169 18.5008 8 18.5008C10.7383 18.5008 12.7059 17.755 13.9624 16.2871C14.3113 15.8795 14.503 15.3605 14.503 14.824V14.2486C14.503 13.835 14.1678 13.4997 13.7542 13.4997ZM8 0.00439453C10.7614 0.00439453 13 2.24297 13 5.00439C13 7.76582 10.7614 10.0044 8 10.0044C5.23857 10.0044 3 7.76582 3 5.00439C3 2.24297 5.23857 0.00439453 8 0.00439453ZM8 1.50439C6.067 1.50439 4.5 3.0714 4.5 5.00439C4.5 6.93739 6.067 8.50439 8 8.50439C9.93299 8.50439 11.5 6.93739 11.5 5.00439C11.5 3.0714 9.93299 1.50439 8 1.50439Z" fill="black" />
        </svg>
    );
};

const IconThreshold = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.54322 5.48256C7.84003 4.39564 9.46603 3.6886 11.25 3.53263V5.25C11.25 5.66421 11.5858 6 12 6C12.4142 6 12.75 5.66421 12.75 5.25V3.53263C16.929 3.89798 20.2412 7.28742 20.4855 11.5H18.75C18.3358 11.5 18 11.8358 18 12.25C18 12.6642 18.3358 13 18.75 13H20.4444C20.1837 15.3116 19.0211 17.2483 17.2765 18.6683C16.9553 18.9298 16.9068 19.4022 17.1683 19.7235C17.4298 20.0447 17.9022 20.0932 18.2235 19.8317C20.45 18.0194 21.8936 15.4382 21.9944 12.3473C21.9985 12.3154 22.0006 12.283 22.0006 12.25C22.0006 12.2301 21.9998 12.2103 21.9983 12.1907C21.9994 12.1274 22 12.0638 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.2522 3.52303 17.9538 5.76986 19.8262C6.08807 20.0913 6.56099 20.0483 6.82617 19.7301C7.09134 19.4119 7.04835 18.939 6.73014 18.6738C5.01891 17.2478 3.82673 15.3069 3.55764 13H5.24963C5.66385 13 5.99963 12.6642 5.99963 12.25C5.99963 11.8358 5.66385 11.5 5.24963 11.5H3.51446C3.62363 9.61804 4.34508 7.90037 5.48256 6.54322L6.71967 7.78033C7.01256 8.07322 7.48744 8.07322 7.78033 7.78033C8.07322 7.48744 8.07322 7.01256 7.78033 6.71967L6.54322 5.48256ZM16.7589 6.63409C16.5207 6.44971 16.1824 6.45611 15.9516 6.64937L15.7344 6.83167C15.596 6.9479 15.3979 7.1145 15.1588 7.31609C14.6808 7.71919 14.0386 8.26249 13.3826 8.82286C12.727 9.38283 12.0555 9.96154 11.5197 10.4349C11.2521 10.6713 11.0157 10.8838 10.831 11.0556C10.6593 11.2154 10.4986 11.3707 10.4112 11.4787C9.75823 12.2863 9.89798 13.4588 10.7234 14.0977C11.5488 14.7365 12.7472 14.5998 13.4002 13.7923C13.4876 13.6842 13.6051 13.4955 13.7245 13.2953C13.8529 13.0799 14.0099 12.8059 14.1835 12.4967C14.5311 11.8777 14.9523 11.1053 15.3585 10.3522C15.7649 9.59869 16.1576 8.86226 16.4486 8.31438C16.5941 8.04039 16.7142 7.81345 16.798 7.65496L16.9294 7.40617C17.0685 7.14203 16.9971 6.81847 16.7589 6.63409Z" fill="black" />
        </svg>
    );
};

const IconPage = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M13.75 2C14.908 2 15.8617 2.87478 15.9862 3.99956L17.75 4C18.9926 4 20 5.00736 20 6.25V11.4982C19.5258 11.3004 19.0232 11.1572 18.5 11.0764V6.25C18.5 5.83579 18.1642 5.5 17.75 5.5L15.621 5.5002C15.2173 6.1031 14.53 6.5 13.75 6.5H10.25C9.46998 6.5 8.78267 6.10308 8.37902 5.5002L6.25 5.5C5.83579 5.5 5.5 5.83579 5.5 6.25V19.75C5.5 20.1642 5.83579 20.5 6.25 20.5H11.7322C12.0194 21.051 12.3832 21.5557 12.8096 22H6.25C5.00736 22 4 20.9926 4 19.75V6.25C4 5.00736 5.00736 4 6.25 4L8.01379 3.99944C8.13841 2.87472 9.09205 2 10.25 2H13.75ZM15.9895 4.03161C15.9885 4.0209 15.9874 4.01022 15.9862 3.99956L15.9895 4.03161ZM15.9948 4.09595L16 4.25C16 4.19402 15.998 4.13852 15.9939 4.08357L15.9948 4.09595ZM13.75 3.5H10.25C9.83579 3.5 9.5 3.83579 9.5 4.25C9.5 4.66421 9.83579 5 10.25 5H13.75C14.1642 5 14.5 4.66421 14.5 4.25C14.5 3.83579 14.1642 3.5 13.75 3.5ZM14.2782 13.9756C14.5929 15.0658 13.9385 16.1992 12.837 16.4718L12.2528 16.6163C12.2079 16.904 12.1845 17.1992 12.1845 17.5C12.1845 17.8147 12.2101 18.1232 12.2591 18.4234L12.7986 18.5533C13.9111 18.8213 14.5724 19.9663 14.2486 21.0638L14.0623 21.6951C14.5014 22.0807 15.0019 22.3939 15.5464 22.6166L16.0397 22.0979C16.8283 21.2687 18.1505 21.2689 18.9388 22.0984L19.4375 22.6232C19.9811 22.4029 20.481 22.0927 20.9203 21.7103L20.7223 21.0244C20.4075 19.9341 21.0619 18.8007 22.1635 18.5281L22.7472 18.3837C22.7921 18.096 22.8155 17.8008 22.8155 17.5C22.8155 17.1853 22.7899 16.8767 22.7408 16.5764L22.2019 16.4466C21.0894 16.1787 20.428 15.0337 20.7519 13.9361L20.9381 13.3053C20.4989 12.9195 19.9984 12.6063 19.4539 12.3835L18.9608 12.902C18.1722 13.7313 16.8499 13.731 16.0617 12.9015L15.5629 12.3767C15.0193 12.5969 14.5193 12.907 14.0801 13.2894L14.2782 13.9756ZM17.5 19C16.6994 19 16.0503 18.3284 16.0503 17.5C16.0503 16.6716 16.6994 16 17.5 16C18.3006 16 18.9497 16.6716 18.9497 17.5C18.9497 18.3284 18.3006 19 17.5 19Z" fill="#212121" />
        </svg>
    );
};

const IconEmail = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15.25 0C16.7688 0 18 1.23122 18 2.75V9.02182C17.5368 8.72526 17.0335 8.48583 16.5 8.3135V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5H2.75C2.05964 1.5 1.5 2.05964 1.5 2.75V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H8.3135C8.48583 17.0335 8.72526 17.5368 9.02182 18H2.75C1.23122 18 0 16.7688 0 15.25V2.75C0 1.23122 1.23122 0 2.75 0H15.25ZM11.25 8.5C11.4124 8.5 11.5627 8.5516 11.6855 8.63929C10.9801 8.97864 10.3455 9.44157 9.80957 10H3.75C3.33579 10 3 9.66421 3 9.25C3 8.8703 3.28215 8.55651 3.64823 8.50685L3.75 8.5H11.25ZM3.75 12.5H8.3135C8.15903 12.9782 8.05846 13.4805 8.01894 14H3.75C3.33579 14 3 13.6642 3 13.25C3 12.8703 3.28215 12.5565 3.64823 12.5068L3.75 12.5ZM14.25 4.5H3.75L3.64823 4.50685C3.28215 4.55651 3 4.8703 3 5.25C3 5.66421 3.33579 6 3.75 6H14.25L14.3518 5.99315C14.7178 5.94349 15 5.6297 15 5.25C15 4.83579 14.6642 4.5 14.25 4.5ZM9.50018 12.6285L9.9474 13.0588C10.7651 13.8456 10.7651 15.1544 9.9474 15.9412L9.50018 16.3715C9.70074 16.945 9.99084 17.4742 10.353 17.9409L10.9549 17.7631C12.0524 17.4388 13.1976 18.0998 13.4659 19.2122L13.6385 19.9281C13.9189 19.9754 14.2067 20 14.5 20C14.7933 20 15.0811 19.9754 15.3614 19.9281L15.5341 19.2122C15.8024 18.0998 16.9476 17.4388 18.0451 17.7631L18.647 17.9409C19.0092 17.4742 19.2993 16.945 19.4998 16.3715L19.0526 15.9412C18.2349 15.1544 18.2349 13.8456 19.0526 13.0588L19.4998 12.6285C19.2993 12.055 19.0092 11.5258 18.647 11.0591L18.0451 11.2369C16.9476 11.5612 15.8024 10.9002 15.5341 9.7878L15.3614 9.07186C15.0811 9.02458 14.7933 9 14.5 9C14.2067 9 13.9189 9.02458 13.6385 9.07186L13.4659 9.7878C13.1976 10.9002 12.0524 11.5612 10.9549 11.2369L10.353 11.0591C9.99084 11.5258 9.70074 12.055 9.50018 12.6285ZM14.5 16C13.6994 16 13.0503 15.3284 13.0503 14.5C13.0503 13.6716 13.6994 13 14.5 13C15.3006 13 15.9497 13.6716 15.9497 14.5C15.9497 15.3284 15.3006 16 14.5 16Z" fill="#212121" />
        </svg>
    );
};

const IconNotification = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M10 3C10 1.34315 11.3431 0 13 0H15C16.6569 0 18 1.34315 18 3V5C18 6.65685 16.6569 8 15 8H13C11.3431 8 10 6.65685 10 5V3ZM2.55031 0.898434C3.15281 -0.299476 4.84719 -0.299479 5.44969 0.898432L7.82157 5.61429C8.371 6.70668 7.58511 8 6.37189 8L1.62811 8C0.414889 8 -0.371003 6.70668 0.178424 5.61429L2.55031 0.898434ZM3.93681 1.51545C3.92838 1.52044 3.91066 1.53207 3.89036 1.57242L1.51848 6.28828C1.50143 6.32217 1.49918 6.34594 1.5002 6.36416C1.50144 6.38629 1.50876 6.4125 1.52416 6.43785C1.53956 6.46319 1.55769 6.47894 1.57169 6.48716C1.58205 6.49324 1.5973 6.5 1.62811 6.5L6.37189 6.5C6.4027 6.5 6.41795 6.49324 6.42831 6.48715C6.44231 6.47894 6.46044 6.46319 6.47584 6.43784C6.49124 6.4125 6.49856 6.38629 6.4998 6.36416C6.50082 6.34593 6.49857 6.32217 6.48152 6.28828L4.10964 1.57242C4.08934 1.53207 4.07161 1.52044 4.06318 1.51545C4.04978 1.50751 4.02805 1.5 4 1.5C3.97195 1.5 3.95022 1.50751 3.93681 1.51545ZM8 14C8 16.2091 6.20914 18 4 18C1.79086 18 0 16.2091 0 14C0 11.7909 1.79086 10 4 10C6.20914 10 8 11.7909 8 14ZM6.5 14C6.5 12.6193 5.38071 11.5 4 11.5C3.58277 11.5 3.18942 11.6022 2.84361 11.7829L6.21705 15.1564C6.39779 14.8106 6.5 14.4172 6.5 14ZM4 16.5C4.41723 16.5 4.81058 16.3978 5.15639 16.2171L1.78295 12.8436C1.60221 13.1894 1.5 13.5828 1.5 14C1.5 15.3807 2.61929 16.5 4 16.5ZM13.5199 10.1163C13.8202 9.96122 14.1798 9.96122 14.4801 10.1163L17.4537 11.6521C17.7898 11.8256 18 12.1663 18 12.5373V15.4627C18 15.8337 17.7898 16.1744 17.4537 16.3479L14.4801 17.8837C14.1798 18.0388 13.8202 18.0388 13.5199 17.8837L10.5463 16.3479C10.2102 16.1744 10 15.8337 10 15.4627V12.5373C10 12.1663 10.2102 11.8256 10.5463 11.6521L13.5199 10.1163ZM11.5 12.8477V15.1523L14 16.4434L16.5 15.1523V12.8477L14 11.5566L11.5 12.8477Z" fill="#212121" />
        </svg>
    );
};

const IconUserDetails = ({ ...props }: SvgIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
            <path d="M5 0C3.89543 0 3 0.895431 3 2V7.04148C3.1626 7.0142 3.32964 7 3.5 7C3.67036 7 3.8374 7.0142 4 7.04148V2C4 1.44772 4.44772 1 5 1H9V4.5C9 5.32843 9.67157 6 10.5 6H14V14C14 14.5523 13.5523 15 13 15H7.96174C7.90868 15.3433 7.79987 15.6814 7.63157 16H13C14.1046 16 15 15.1046 15 14V5.41421C15 5.01639 14.842 4.63486 14.5607 4.35355L10.6464 0.43934C10.3651 0.158035 9.98361 0 9.58579 0H5ZM13.7929 5H10.5C10.2239 5 10 4.77614 10 4.5V1.20711L13.7929 5ZM5.5 10C5.5 11.1046 4.60457 12 3.5 12C2.39543 12 1.5 11.1046 1.5 10C1.5 8.89543 2.39543 8 3.5 8C4.60457 8 5.5 8.89543 5.5 10ZM7 14.5C7 15.7452 6 17 3.5 17C1 17 0 15.7499 0 14.5C0 13.6716 0.671573 13 1.5 13H5.5C6.32843 13 7 13.6716 7 14.5Z" fill="#1565C0" />
        </svg>
    );
};

const NameIcon = () => {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3C4.89543 3 4 3.89543 4 5C4 6.10457 4.89543 7 6 7C7.10457 7 8 6.10457 8 5C8 3.89543 7.10457 3 6 3ZM5 5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5ZM8.5 8L3.49998 8C2.67156 8 2 8.67161 2 9.5C2 10.6161 2.45897 11.5103 3.21215 12.1148C3.95342 12.7098 4.94692 13 6 13C7.05308 13 8.04658 12.7098 8.78785 12.1148C9.54103 11.5103 10 10.6161 10 9.5C10 8.67157 9.32843 8 8.5 8ZM3.49998 9L8.5 9C8.77614 9 9 9.22386 9 9.5C9 10.3169 8.67547 10.9227 8.1619 11.3349C7.63642 11.7567 6.87992 12 6 12C5.12008 12 4.36358 11.7567 3.8381 11.3349C3.32453 10.9227 3 10.3169 3 9.5C3 9.22387 3.22387 9 3.49998 9Z" fill="#212121" />
        </svg>
    )
}

export {
    IconFilter,
    IconCheckbox,
    IconChecked,
    IconView,
    IconEdit,
    IconDisable,
    IconBirthday,
    IconDelete,
    DashboardIcon,
    IconAdmin,
    IconWebuser,
    IconReports,
    IconThreshold,
    IconPage,
    IconEmail,
    IconNotification,
    IconUserDetails,
    NameIcon
}
