import { gql } from "@apollo/client";

export const CREATE_GLUCOSE_THRESHOLD = gql`
mutation CreateThreshold($input: CreateThresholdDTO!) {
  createThreshold(input: $input) {
    message
    pagination {
      hasNextPage
      total
    }
    threshold {
      userId
      adminId
      severeHyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      severeHypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
    }
  }
}
`

export const UPDATE_GLUCOSE_THRESHOLD = gql`
mutation UpdateThreshold($updateThresholdId: String!, $input: UpdateThresholdDTO!) {
  updateThreshold(id: $updateThresholdId, input: $input) {
    message
    success
    pagination {
      total
      hasNextPage
    }

    threshold {
      userId
      adminId
      severeHyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hyperglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      hypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
      severeHypoglycaemia {
        lowerLimit
        upperLimit
        color
        select
      }
    }
  }
}

`