import { gql } from '@apollo/client';

export const GET_ALL_APP_USERS = gql`
  query GetAllAppUsers($input: GetAppUsersDTO!) {
    getAllAppUsers(input: $input) {
      message
      users {
        _id
        authProvider
        authProviderId
        password
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        status
        bio
        lastLoggedInAt
        createdAt
        updatedAt
        contacts {
          phone {
            dialCode
            number
            isVerified
          }
          emailDetail {
            email
            isVerified
          }
        }
        enrollIds
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_APP_USER = gql`
  query ($id: String!) {
    getAppUser(id: $id) {
      message
      user {
        _id
        authProvider
        authProviderId
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        bio
        status
        lastLoggedInAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_USERS_TO_ENROLL = gql`
  query GetAllAppUsersToEnroll($enrollId: String!, $input: GetAppUsersDTO!) {
    getAllAppUsersToEnroll(enrollId: $enrollId, input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      users {
        _id
        authProvider
        authProviderId
        password
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        status
        bio
        lastLoggedInAt
        createdAt
        updatedAt
        contacts {
          phone {
            dialCode
            number
            isVerified
          }
          emailDetail {
            email
            isVerified
          }
        }
      }
    }
  }
`;
