// theme constant
export const gridSpacing = 3;
export const drawerWidth = 300;
export const appDrawerWidth = 320;
const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export default LAYOUT_CONST;
