import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";

// project imports

import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGQL } from "hooks/notificationTemplete/useGQL";
import { resetForm } from "store/slices/form";
import { NotificationTempleteType } from "./constants/types";
import Spinner from "components/spinner";
import NotificationTempleteTable from "./table";

// ==============================|| PROFILE 2 ||============================== //

const NotificationManagement = () => {
  const navigate = useNavigate();
  const rootDispatch = useDispatch();
  const { GET_NOTIFICATION_TEMPLATES_LIST } = useGQL();

  const { loading, data } = GET_NOTIFICATION_TEMPLATES_LIST();
  const [notification, setNotifications] = useState<NotificationTempleteType[]>(
    []
  );

  useEffect(() => {
    sessionStorage.clear();
    rootDispatch(resetForm());
  }, []);

  const handleEdit = async (event: any | null, id: string) => {
    navigate(`/notification-management/edit/${id}`);
  };

  useEffect(() => {
    if (data?.getAllNotification) {
      setNotifications(data.getAllNotification?.data);
    }
  }, [data]);

  const handleCreateNotification = () => {
    navigate("/notification-management/create");
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard
          title={
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={1.5}
            >
              <Typography variant="h3">Notification Management</Typography>
              <Button onClick={handleCreateNotification} variant={"outlined"}>
                Add Notification
              </Button>
            </Stack>
          }
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <NotificationTempleteTable
                data={notification}
                handleEdit={handleEdit}
              />
            </>
          )}
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default NotificationManagement;
