const TriggerConditionCase = {
    and: "AND",
    or: "OR"
}
const TriggerCondition = {
    greater_then: "Greater than",
    equals_to: "Equal to",
    less_then: "Lesser than",
    greater_then_equals_to: "Greater than or equal to",
    less_then_equals_to: "Lesser than or equal to"
}
const PurposeNotification = {
    gulcose_level_increased: "Glucose level increased",
    gulcose_level_decreased: "Glucose level decreased",
    changing_user_result: "Change in patient’s test result reading"
}
const Visibility = {
    private: "Private",
    public: "Public"
}
const Status = {
    active: "Active",
    disabled: "Disabled"
}

export const getNotificationTriggerTitle = (key:string) =>{
    if(!key) return ""
    return TriggerCondition[key]
}

export const getTriggerConditionCase = (key:string) =>{
    if(!key) return ""
    return TriggerConditionCase[key]
}

export const getPurposeNotification = (key:string | undefined) =>{
    if(!key) return ""
    return PurposeNotification[key]
}

export const getVisibility = (key:number | string) =>{
    if(!key) return ""
    return Visibility[key]
}

export const getStatus = (key:string | number) =>{
    if(!key) return ""
    return Status[key]
}