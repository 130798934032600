import { gql } from '@apollo/client';

export const LIST_PAGES = gql`
query ListPages($input: GetPagesDTO!) {
    listPages(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      pages {
        _id
        title
        status
        content
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_PAGE = gql`
  query ListPage($id: String!) {
    listPage(id: $id) {
      message
      page {
          _id
          title
          content
          createdAt
          status
          updatedAt
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;